@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* UTILITY */
  .flex-centered {
    @apply flex justify-center items-center;
  }

  /* NAVBAR */
  .navbar {
    @apply w-full p-3 md:px-5 dark:bg-gray-900  tracking-wide font-semibold 
    border-b-2 border-yellow-500 shadow-lg flex justify-between items-start  sm:items-center 
    rounded-b bg-indigo-800;
  }

  .dark-mode-btn {
    @apply bg-yellow-400 focus:outline-none 
    font-medium hover:bg-yellow-500 px-1 py-1.5  
    rounded sm:p-2 text-black text-xs uppercase;
  }
  .toggle-mode-icons {
    @apply fill-current text-2xl hover:text-yellow-400 text-gray-50;
  }
  .about-btn {
    @apply bg-yellow-600  font-semibold 
    hover:bg-yellow-500   p-2 mt-2 sm:mt-0 rounded 
    text-xs text-gray-50 uppercase;
  }

  /* Search Form */
  .input {
    @apply bg-white dark:bg-gray-400 dark:placeholder-gray-200 dark:text-gray-50 
    focus:border-black focus:outline-none font-medium mt-2 
    overflow-hidden placeholder-gray-400 px-3  pl-9 rounded-lg shadow-lg 
    text-gray-700 text-lg py-2 tracking-wide w-full capitalize focus:ring-2 focus:ring-blue-300;
  }
  /* End Search Form */

  /* SinglePicture  */
  .photo-card {
    @apply bg-gray-50 cursor-pointer dark:bg-gray-900 hover:scale-105 
    rounded-b-lg shadow-lg text-white transform transition-transform;
  }
  .stats-icon {
    @apply flex items-center space-x-1 font-bold dark:text-white
       text-indigo-700;
  }
  .download-btn {
    @apply bg-indigo-600  dark:bg-yellow-600 dark:hover:bg-yellow-500 
    dark:text-yellow-50 flex font-semibold hover:bg-indigo-700
    hover:text-white items-center px-1 rounded text-indigo-50 text-sm transition-colors;
  }
  .tag {
    @apply bg-indigo-500 cursor-pointer dark:bg-yellow-600 
      dark:hover:bg-yellow-500 dark:text-yellow-50 hover:bg-indigo-400 px-2 
      py-1 rounded-full text-xs transition-colors;
  }
  /* SinglePicture End */

  /* About Page */
  .heading {
    @apply flex-centered border-yellow-500 dark:text-gray-50  m-auto px-2 py-1 
      rounded text-3xl text-center text-gray-800 tracking-wide  mb-10 mt-16;
  }

  /* Modal */
  .modal {
    @apply bg-green-200  my-5 p-2 px-3 ring-1
       ring-green-800 rounded text-green-900 mx-auto w-max sm:max-w-max;
  }
  .icons {
    @apply inline-block mr-3 dark:text-yellow-500 fill-current text-4xl text-indigo-700;
  }
}

@layer utilities {
  .newClass {
    animation: modal 7s ease-in-out 1;
  }
  @keyframes modal {
    0%,
    100% {
      transform: translateY(-2.5rem);
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    35% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
